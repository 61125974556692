.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: rem($header-height);
  z-index: 10;
  padding: rem($unit-tiny);
  background-color: $white;
  box-shadow: 0 5px 10px rgba(23, 14, 32, 0.2);

  display: flex;
  align-items: center;

  .c-header_logo {
    height: 100%;
    display: flex;
    align-items: center;

    .slogan {
      font-size: 25px;
      height: 100%;
      line-height: 28px;
      display: flex;
      align-items: center;
      padding: 1rem 0;
      font-weight: 400;
      // @media (max-width: $to-tiny) {
      //   line-height: 24px;
      //   font-size: 20px;
      // }
    }

    .nom_commune {
      font-size: 63px;
      line-height: 60px;
      margin-left: -4px;
    }

    img {
      height: 100%;
      padding: 1rem 0;
    }
  }

  .c-header_nav {
    display: flex;
    align-items: center;
    margin-left: auto;
    div {
      & > span {
        @media (max-width: $to-tiny) {
          display: none;
        }
      }
    }
  }

  .c-header_nav_burger {
    .c-header_nav_icon {
      position: relative;
      width: 25px;
      height: 22px;
      cursor: pointer;
      float: right;
      
      span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: $white;
        border-radius: 2px;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }        
        &:nth-child(2) {
          top: 8px;
          width: 75%;
          right: 0;
        }        
        &:nth-child(3) {
          top: 16px;
        }
      }     
    }
    &:hover {
      .c-header_nav_icon {
        span {
          &:nth-child(2) {
            right: 25%;
          }
        }
      }
    }
    &.is-active {
      .c-header_nav_icon {
        span {
          &:nth-child(1) {
            top: 10px;
            transform: rotate(135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            // left: -60px;
          }
          &:nth-child(3) {
            top: 10px;
            transform: rotate(-135deg);
          }
        }
      }
    }
  }

  @media (min-width: $from-small) {
    padding: 0 rem(40px);
  }
  @media (max-width: $to-small) {
    padding: 0 rem(20px);
  }
}

.has-navOpen {
  .c-header {
    border-bottom: 2px solid $grey-light;
    box-shadow: none;
  }
}

.pojo-a11y-toolbar-toggle {
  display: none;
}