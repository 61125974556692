.u-c-primary {
    color: $primary;
}
.u-c-primary-dark {
    color: $primary-dark;
}
.u-c-second {
    color: $second;
}
.u-c-second-dark {
    color: $second-dark;
}
.u-c-grey {
    color: $grey;
}
.u-c-grey-light {
    color: $grey-light;
}
.u-c-grey-extra-dark {
    color: $grey-extra-dark;
}
.u-c-white {
    color: $white;
}
.u-c-red {
    color: $red;
}
.u-c-green {
    color: $green;
}
.u-c-black {
    color: $black;
}

.u-bg-primary {
    background-color: $primary;
}
.u-bg-primary2 {
    background-color: $primary2;
}
.u-bg-primary-dark {
    background-color: $primary-dark;
}
.u-bg-second {
    background-color: $second;
}
.u-bg-second-dark {
    background-color: $second-dark;
}
.u-bg-grey {
    background-color: $grey;
}
.u-bg-grey-light {
    background-color: $grey-light;
}
.u-bg-grey-extra-dark {
    background-color: $grey-extra-dark;
}
.u-bg-white {
    background-color: $white;
}

.u-text-shadow {
    text-shadow: 0 0px 10px rgb(0, 0, 0);
}